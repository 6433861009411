import { createBrowserRouter } from "react-router-dom";
import App from './App';
import CmsPannel from "./components/Layouts/CmsPannel";
import Login from "./views/Login";
import Admin from "./views/Admin";


export const router = createBrowserRouter([
    {
        path: '/',
        element: <App/>
    },
    {
        path: '/cpannel',
        element: <Admin/>,
        children:[
            {
                path: '',
                element: <CmsPannel/>
            },
            {
                path: 'login',
                element: <Login/>
            }
        ]
    }
]);