import React from 'react';
import {BsFillTelephoneFill, BsFacebook, BsLinkedin, BsTwitter, BsInstagram} from 'react-icons/bs';

import logo from '../assets/sfLogo.PNG';
import flag from '../assets/Malawi.png';
import DrawerNav from './DrawerNav';

const LogoSection = () => {
    return (
        <div className={`py-5 sm:py-2 px-10 sticky top-0 bg-white sm:flex justify-between items-center`} style={{ zIndex: 2 }}>
            <p className='hidden sm:flex gap-2 items-center'>
                <img src={flag} alt='' className='w-[2rem]'/>
                <BsFillTelephoneFill className='hidden sm:block text-green-800'/> 
                <a href='tel:+265888998100' className='hidden sm:block text-gray-800 text-md font-semibold'>+265 888 998 100</a>
            </p>
            <div className='flex sm:justify-center justify-between items-center'>
                <img src={logo} alt='logo' className='w-[70%] sm:w-[40%]'/>
                <div className='sm:hidden'>
                    <DrawerNav/>
                </div>
            </div>
            <div className='hidden sm:flex items-center gap-5'>
                <a href><BsTwitter size={20} className='text-green-800'/></a>
                <a href='https://www.facebook.com/profile.php?id=100063644846692&mibextid=ZbWKwL' target='_blank' rel="noreferrer"><BsFacebook size={20} className='text-green-800'/></a>
                <a href='https://www.linkedin.com/company/smartfrms/' target='_blank' rel="noreferrer"><BsLinkedin size={20} className='text-green-800'/></a>
                <a href='https://www.instagram.com/smartfarms7/' target='_blank' rel="noreferrer"><BsInstagram size={20} className='text-green-800'/></a>
            </div>
        </div>
    );
}

export default LogoSection;
