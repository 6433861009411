import { Avatar, Dropdown, Navbar } from 'flowbite-react'
import React from 'react'
import { useAuthContext } from '../../contexts/authProvider'
import logo from '../../assets/Logo.png';

export default function CmsNavBar() {
    const {setToken} = useAuthContext();

    const signOut = () => {
        setToken(null);
    }
    return (
        <div className=''>
            <Navbar
                fluid={true}
                className='bg-[#403f3e] border-b-2 border-b-[#535251]'
            >
                <Navbar.Brand href="https://flowbite.com/">
                    <span className="self-center whitespace-nowrap text-xl font-semibold text-white">
                        SmartFarms CPannel
                    </span>
                </Navbar.Brand>
                <div className="flex md:order-2">
                    <Dropdown
                        arrowIcon={false}
                        inline={true}
                        label={<Avatar alt="User settings" img={logo} rounded={true} />}
                    >
                        <Dropdown.Header>
                            <span className="block text-sm">
                                Bonnie Green
                            </span>
                            <span className="block truncate text-sm font-medium">
                                name@flowbite.com
                            </span>
                        </Dropdown.Header>
                        <Dropdown.Item className=''>
                            Dashboard
                        </Dropdown.Item>
                        <Dropdown.Item>
                            Settings
                        </Dropdown.Item>
                        <Dropdown.Item>
                            Earnings
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                            onClick={() => signOut()}
                        >
                            Sign out
                        </Dropdown.Item>
                    </Dropdown>
                    <Navbar.Toggle />
                </div>
                {/* <Navbar.Collapse>
                    <ul className='flex gap-10 text-white'>
                        <li>Home</li>
                        <li>Pages</li>
                        <li>Home</li>
                        <li>Home</li>
                        <li>Home</li>
                    </ul>
                </Navbar.Collapse> */}
                
            </Navbar>
        </div>
    )
}
