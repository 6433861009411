import React from 'react';
import { MdMiscellaneousServices } from 'react-icons/md';
import { TbCertificate } from 'react-icons/tb';
import { AiOutlineSolution } from 'react-icons/ai';

import chooseImg from '../assets/smartFarms.PNG';

const ChooseUs = () => {
    return (
        <div className='w-full min-h-[50vh]' id='next'>
            <div className='container p-5 sm:p-10'>
                <div className='flex mt-5 sm:mt-14'>
                    <div className='hidden lg:block min-h-[60vh] w-[50%]'>
                        <img src={chooseImg} alt="" className='h-full' />
                    </div>
                    <div className='w-full lg:w-[50%] sm:ml-10 sm:pl-20'>
                        <div
                            className='w-full'
                        >
                            <div class="animate_top bb ze rj ki xn vq">
                                <h2
                                    x-text="sectionTitle"
                                    class="fk vj pr kk wm on/5 gq/2 bb _b"
                                >
                                    Why choose us
                                </h2>
                                <p class="bb on/5 wo/5 hq" x-text="sectionTitleText"></p>
                            </div>


                        </div>
                        <di>
                            <div className='mt-7 shadow-2xl lg:shadow-none p-2 sm:p-0'>
                                <div className='flex gap-7'>
                                    <TbCertificate color='' className='text-gray-900' size={40} />
                                    <h1 className=' text-xl font-semibold text-gray-900'>Expertise and experience</h1>
                                </div>
                                <p className='sm:w-[30vw] mt-2 pl-10 text-gray-700'>From cutting-edge technology solutions to expert guidance and support, we offer everything farmers need to optimize their business and reach their goals</p>
                            </div>
                            <div className='mt-5 shadow-2xl lg:shadow-none p-2 sm:p-0'>
                                <div className='flex gap-7'>
                                    <AiOutlineSolution className='text-gray-900' size={40} />
                                    <h1 className=' text-xl font-semibold text-gray-900'>Customized solutions</h1>
                                </div>
                                <p className='sm:w-[30vw] mt-2 pl-10 text-gray-700'>Our team works closely with farmers to design and implement personalized solutions that meet their unique requirements</p>
                            </div>
                            <div className='mt-5 shadow-2xl lg:shadow-none p-2 sm:p-0'>
                                <div className='flex gap-7'>
                                    <MdMiscellaneousServices className='text-gray-900' size={40} />
                                    <h1 className=' text-xl font-semibold text-gray-900'>Comprehensive services</h1>
                                </div>
                                <p className='sm:w-[30vw] mt-2 pl-10 text-gray-700'>By offering a complete package of agricultural services, we are able to support farmers every step of the way on their journey to success.</p>
                            </div>
                        </di>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChooseUs;
