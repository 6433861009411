import React, { useEffect, useState } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HeroSlider, { Overlay, Slide} from "hero-slider";
import { Link } from 'react-scroll';

import bg from '../assets/landingBG.png';
import gh1 from '../assets/gh1.png';
import gh2 from '../assets/gh2.png';
import gh3 from '../assets/gh3.png';

const slideData = [
    {
        title: 'Welcome to SmartFarms',
        subtitle: 'Fulfilling the dream of agriculture'
    },
    {
        title: 'Expertise and Experience',
        subtitle: 'Years of knowledge and experience at your service'
    },
    {
        title: 'Customized Solutions',
        subtitle: 'Tailored solutions to fit your unique needs'
    },
    {
        title: 'Comprehensive services',
        subtitle: 'All the services you need in one place'
    },
    {
        title: 'Satisfied Customer',
        subtitle: 'Join our community of satisfied customers today'
    },

]

const Home = () => {
    const [currentTitle, setCurrentTitle] = useState(0);
    useEffect(() => {
      Aos.init();
    }, []);
    return (
        <div className='' id='home'>
            <HeroSlider
                style={{ zIndex: 1 }}
                height={"84.2vh"}
                autoplay
                controller={{
                    initialSlide: 1,
                    slidingDuration: 500,
                    slidingDelay: 100,
                    onSliding: (nextSlide) =>{
                        if(currentTitle + 1 === slideData.length){
                            setCurrentTitle(0);
                        }else{
                            setCurrentTitle(currentTitle + 1);
                        }
                    },
                    onBeforeSliding: (previousSlide, nextSlide) =>
                    console.debug(
                        "onBeforeSliding(previousSlide, nextSlide): ",
                        previousSlide,
                        nextSlide
                    ),
                    onAfterSliding: (nextSlide) =>
                    console.debug("onAfterSliding(nextSlide): ", nextSlide)
                }}
            >
                <Overlay>
                    <div className='h-full flex justify-center sm:justify-end' style={{ zIndex: 1 }}>
                        <div className='w-full sm:w-[50%] h-full flex flex-col justify-center sm:ml-0'>
                            <h1 className='text-[3rem] font-bold text-gray-100 text-center sm:text-start font-roboto' data-aos="fade-right">{slideData[currentTitle].title}</h1>
                            <p className='text-3xl text-gray-200 mt-4 text-center sm:text-start' data-aos='fade-left'>{slideData[currentTitle].subtitle}</p>
                            <div className='w-full flex justify-center sm:justify-start gap-5'>
                                <button className='mt-10 w-[30vw] sm:w-[10vw] bg-accent py-2 text-white rounded-lg cursor-pointer'>
                                    <Link to='next' smooth={true} duration={1500} offset={-70}>Read more</Link>
                                </button>
                                <button className='w-[30vw] sm:w-[10vw] mt-10 border-2 border-gray-300 text-white rounded-lg cursor-pointer'>
                                <Link to='services' smooth={true} duration={1500} offset={-70}>Services</Link>
                                </button>
                            </div>
                            
                        </div>
                    </div>
                </Overlay>

                <Slide
                    shouldRenderMask
                    background={{
                    backgroundImageSrc: bg
                    }}
                />

                <Slide
                    shouldRenderMask
                    background={{
                    backgroundImageSrc: gh1
                    }}
                />

                <Slide
                    shouldRenderMask
                    background={{
                    backgroundImageSrc: gh2
                    }}
                />

                <Slide
                    shouldRenderMask
                    background={{
                    backgroundImageSrc: gh3,
                    }}
                />
            </HeroSlider>
    </div>
    );
}

export default Home;
