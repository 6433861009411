import React from 'react';
import {BsWhatsapp, BsTwitter, BsFacebook, BsInstagram, BsLinkedin} from 'react-icons/bs';

//import profile from '../assets/profile.png';

const TeamMemberCard = ({profile, name, position}) => {
    return (
        <div className='flex flex-col items-center shadow-md p-5 py-10 w-full bg-[#f5f5f5] rounded-lg'>
            <div 
            style={{ 
                width: '150px',
                height: '150px',
                borderRadius: '50%',
                overflow: 'hidden'
             }}
             className='bg-gray-300'
            >
                <img src={profile} alt="" className='w-full'/>
            </div>
            <h2 className='mt-5 text-[1.4rem] font-semibold text-gray-700 text-center'>{name}</h2>
            <i className='text-gray-600 text-center'>{position}</i>
            <div className='mt-[2rem] flex justify-around items-center gap-3 text-gray-500'>
                <BsWhatsapp/>
                <BsTwitter/>
                <BsFacebook/>
                <BsInstagram/>
                <BsLinkedin/>
            </div>
        </div>
    );
}

export default TeamMemberCard;
