import { GiPlantWatering, GiCow, GiTeacher} from 'react-icons/gi';
import {TiWeatherPartlySunny} from 'react-icons/ti';
import {TbDeviceHeartMonitor} from 'react-icons/tb';
import {GrBarChart} from 'react-icons/gr';

export const engineeringData = [
    {
      title: "Agricultural Automation",
      icon: <TbDeviceHeartMonitor size={40} className="text-black-900" />,
      description:
        "We provide automated systems for tasks such as irrigation, fertilization, and pest control to help farmers save time and reduce manual labor. These systems increase efficiency and reduce labor costs.",
    },
    {
      title: "Livestock Management Systems",
      icon: <GiCow size={40} className="text-black-900" />,
      description:
        "Our technology helps farmers manage and monitor livestock health, feed intake, and overall productivity. This improves animal welfare and overall profitability.",
    },
    {
      title: "Environmental Monitoring",
      icon: <TiWeatherPartlySunny size={40} className="text-black-900" />,
      description:
        "We develop systems for monitoring climate, soil moisture, and other key factors that impact crop growth and production. This helps farmers optimize their use of resources and reduce waste.",
    },
    {
      title: "Data Analytics and Decision Support",
      icon: <GrBarChart size={40} className="text-black-900" />,
      description:
        "We provide data analysis and decision support tools to help farmers make informed decisions about planting, harvesting, and overall farm management. These tools improve efficiency and reduce waste.",
    },
    {
      title: "Irrigation Systems",
      icon: <GiPlantWatering size={40} className="text-black-900" />,
      description:
        "We design affordable, environmentally friendly irrigation systems to help farmers efficiently water their crops and improve yields. These systems increase efficiency and reduce water waste.",
    },
    {
      title: "Training and Support",
      icon: <GiTeacher size={40} className="text-black-900" />,
      description:
        "We provide training and ongoing support to help farmers effectively use technology systems and make the most of their investment. This improves overall efficiency and reduces the learning curve for new technology.",
    },
  ];
  