import React, { useState } from 'react';
import {signInWithEmailAndPassword}  from 'firebase/auth';
import {auth} from '../firebase';
import { useAuthContext } from '../contexts/authProvider';
import { Navigate } from 'react-router-dom';
import { Spinner } from 'flowbite-react';


export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setpassword] = useState('');
  const {token, setToken} = useAuthContext();
  const [loading, setLoading] = useState(false);

  if(token){
    return <Navigate to="/cpannel"/>
  }

  const signIn = (e) => {
    e.preventDefault();
    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredentials => {
      setLoading(false);
      console.log(userCredentials.user.accessToken);
      setToken(userCredentials.user.accessToken);
    })).catch(error => {
      setLoading(false);
      console.log(error);
    })
  }
  return (
    <section class="bg-gray-50 dark:bg-gray-900">
  <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Sign in to your account
              </h1>
              <form onSubmit={signIn} class="space-y-4 md:space-y-6" action="#">
                  <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                      <input 
                        type="email"
                        name="email" 
                        id="email" 
                        onChange={(e) => setEmail(e.target.value)}
                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="enter your email" 
                        required=""
                        />
                  </div>
                  <div>
                      <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                      <input 
                        type="password" 
                        name="password" 
                        id="password" 
                        placeholder="enter password"
                        onChange={(e) => setpassword(e.target.value)}
                        class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        required=""
                        />
                  </div>
                  <button type="submit"  class="w-full p-3 bg-slate-900 text-white rounded-md">{loading ? <Spinner aria-label="Default status example" /> : 'Sign In'}</button>
              </form>
          </div>
      </div>
  </div>
</section>
  )
}
