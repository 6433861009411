import React from 'react';
import About from './components/About';
import ChooseUs from './components/ChooseUs';
import Contact from './components/Contact';

import Home from './components/Home';
import Services from './components/Services';
import Footer from './UI/Footer';
import NavBar from './UI/NavBar';
import LogoSection from './UI/LogoSection';

import './App.css';
import Portfolio from './components/Portfolio';
import Ourteam from './components/ourteam';
import OurPartners from './components/OurPartners';

function App() {
  return (
    <div className="mainApp">
      <LogoSection/>
      <NavBar/>
      <Home/>
      <About/>
      <Services/>
      <ChooseUs/>
      <Ourteam/>
      <Portfolio/>
      <OurPartners/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
