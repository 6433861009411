import React from 'react'
import { Outlet } from 'react-router-dom'

import {AuthProvider} from '../contexts/authProvider';

export default function Admin() {
  return (
    <AuthProvider>
      <Outlet/>
    </AuthProvider>
  )
}
