import React, { useState } from 'react';
//import SectionHeading from '../UI/SectionHeading';
import ServiceCard from '../UI/ServiceCard';
import { engineeringData } from '../UI/EngineeringData';
import { ConsultationData } from '../UI/ConsultationData';
import { capacityBuildingData } from '../UI/CapacityBuildingData';
import { researchData } from '../UI/ResearchData';
import { businessData } from '../UI/BusinessData';
import './Services.css';
// import serviceBG from '../assets/serviceBBg.PNG';

const Services = () => {
    const [currentServices, setCurrentServices] = useState('engineering');
    const [currentData, setCurrentData] = useState(engineeringData);
    console.log(currentData);
    return (
        <div
            className='w-full ' id='services'
        // style={{ 
        //     backgroundImage: `url(${serviceBG})`,
        //     backgroundSize: 'cover',
        //     backgroundPosition: 'center',
        //  }}
        >
            <div className='mx-[6vw] py-14'>
                <div className=''>
                    <div
                        x-data="{ sectionTitle: `Latest Blogs & News`, sectionTitleText: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using.`}"
                    >
                        <div class="animate_top bb ze rj ki xn vq">
                            <h2
                                x-text="sectionTitle"
                                class="fk vj pr kk wm on/5 gq/2 bb _b"
                            >
                                Our Services
                            </h2>
                            <p class="bb on/5 wo/5 hq" x-text="sectionTitleText"></p>
                        </div>


                    </div>
                    <p className='text-gray-400 text-md text-center mt-2'>Experience the Difference with Our Services</p>
                    <ul className='flex flex-wrap mt-5 gap-10 justify-center items-center'>
                        <li
                            className='cursor-pointer text-gray-600 font-mono'
                            onClick={() => { setCurrentServices('engineering'); setCurrentData(engineeringData) }}
                        >
                            <p>Engineering system design</p>
                            <hr className={`text-green-800 p-[1px] bg-green-800 ${currentServices === 'engineering' ? 'shrink' : 'hidden'}`} />
                        </li>
                        <li
                            className='cursor-pointer text-gray-600 font-mono'
                            onClick={() => { setCurrentServices('consultation'); setCurrentData(ConsultationData) }}
                        >
                            <p>Consultation</p>
                            <hr className={`text-green-800 p-[1px] bg-green-800 ${currentServices === 'consultation' ? 'shrink' : 'hidden'}`} />
                        </li>
                        <li
                            className='cursor-pointer text-gray-600 font-mono'
                            onClick={() => { setCurrentServices('capacity'); setCurrentData(capacityBuildingData) }}
                        >
                            <p>Capacity building</p>
                            <hr className={`text-green-800 p-[1px] bg-green-800 ${currentServices === 'capacity' ? 'shrink' : 'hidden'}`} />
                        </li>
                        <li
                            className='cursor-pointer text-gray-600 font-mono'
                            onClick={() => { setCurrentServices('research'); setCurrentData(researchData) }}
                        >
                            <p>Research</p>
                            <hr className={`text-green-800 p-[1px] bg-green-800 ${currentServices === 'research' ? 'shrink' : 'hidden'}`} />
                        </li>
                        <li
                            className='cursor-pointer text-gray-600 font-mono'
                            onClick={() => { setCurrentServices('business'); setCurrentData(businessData) }}
                        >
                            <p>Business enterprise</p>
                            <hr className={`text-green-800 p-[1px] bg-green-800 ${currentServices === 'business' ? 'shrink' : 'hidden'}`} />
                        </li>
                    </ul>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-10 sm:px-[10vw]'>
                    {currentData.map(item => (
                        <ServiceCard icon={item.icon} title={item.title} description={item.description} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Services;
