import { GiPlantRoots, GiCow, GiMoneyStack, GiChemicalDrop } from 'react-icons/gi';

export const ConsultationData = [
  {
    title: 'Crop Consultation',
    icon: <GiPlantRoots size={40} className=''/>,
    description: 'Tailored recommendations and solutions for optimizing crop production and profitability, including field assessments, agronomic support, and market intelligence.'
  },
  {
    title: 'Animal Husbandry Consultation',
    icon: <GiCow size={40} className=''/>,
    description: 'Specialized advice on animal health, nutrition, and breeding to improve the productivity of livestock, including livestock management and herd health support.'
  },
  {
    title: 'Agribusiness Consultation',
    icon: <GiMoneyStack size={40} className=''/>,
    description: 'Comprehensive support for managing operations, marketing, and finances effectively, including market intelligence and financial planning.'
  },
  {
    title: 'Soil Testing',
    icon: <GiChemicalDrop size={40} className=''/>,
    description: 'Advanced soil analysis to determine the nutrient content, pH levels, and other essential factors affecting crop growth and soil health.'
  }
];
