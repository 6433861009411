import React from 'react';

import teamData from './teamData';
import TeamMemberCard from '../UI/TeamMemberCard';

const Ourteam = () => {
    return (
        <div id='team'>
            <div className='p-2 sm:p-10 mt-5 sm:mt-0'>
                <div
                    x-data="{ sectionTitle: `Latest Blogs & News`, sectionTitleText: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using.`}"
                >
                    <div class="animate_top bb ze rj ki xn vq">
                        <h2
                            x-text="sectionTitle"
                            class="fk vj pr kk wm on/5 gq/2 bb _b"
                        >
                            Our Team
                        </h2>
                        <p class="bb on/5 wo/5 hq" x-text="sectionTitleText"></p>
                    </div>


                </div>
                <p className='text-gray-400 text-md text-center mt-2'>Explore our leadership and team of experts</p>
                <div className='mt-10 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 justify-around gap-7'>
                    {teamData.map(member => (
                        <TeamMemberCard profile={member.image} name={member.name} position={member.position} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Ourteam;
