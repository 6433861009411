import React, { useState } from 'react'
import CmsNavBar from '../../UI/cmsPannel/CmsNavBar'
import CmSideBar from '../../UI/cmsPannel/CmSideBar'
import { useAuthContext } from '../../contexts/authProvider'
import { Navigate } from 'react-router-dom';
import Dashboard from '../cpannel/Dashboard';
import Articles from '../cpannel/Articles';
import Team from '../cpannel/Team';
import PageContents from '../cpannel/PageContents';
import News from '../cpannel/News';
import Media from '../cpannel/Media';

export default function CmsPannel() {
  const { token } = useAuthContext();
  const [page, setPage] = useState('dashboard');

  if (!token) {
    return <Navigate to='login' />
  }

  const siwtcNavbar = () => {
    switch(page){
      case 'dashboard':
        return <DashboardNav title={page}/>
      case 'articles':
        return <ArticlesNav title={page}/>
      case 'team':
        return <TeamNav title={page}/>
      case 'pageContent':
        return <PageContentNav title={page}/>
      case 'news':
        return <NewsNav title={page}/>
      case 'media':
        return <MediaNav title={page}/>
      default:
        return(
          <div>
            404 - Page Not Found
          </div>
        )
    }
  }

  const siwtchContent = () => {
    switch(page){
      case 'dashboard':
        return <Dashboard/>
      case 'articles':
        return <Articles/>
      case 'team':
        return <Team/>
      case 'pageContent':
        return <PageContents/>
      case 'news':
        return <News/>
      case 'media':
        return <Media/>
      default:
        return(
          <div>
            404 - Page Not Found
          </div>
        )
    }
  }

  return (
    <div>
      <CmsNavBar />
      <div className='flex'>
        <CmSideBar setMainPage={setPage} page={page}/>
        <div className='w-full p-10' style={{backgroundColor: 'whitesmoke'}}>
          <div className='w-full p-7 bg-white rounded-lg shadow-md'>
              {
               siwtcNavbar() 
              }
          </div>
          <div className='w-full min-h-[70vh] p-10 bg-white mt-7 rounded-lg shadow-md'>
              {
                siwtchContent()
              }
          </div>
        </div>
      </div>

    </div>
  )
}

function DashboardNav({title}){

  return(
    <div className='flex'>
      <h4>{title}</h4>
    </div>
  );
}

function ArticlesNav({title}){

  return(
    <div>
       <h4>{title}</h4>
    </div>
  );
}

function TeamNav({title}){

  return(
    <div>
       <h4>{title}</h4>
    </div>
  );
}

function NewsNav({title}){

  return(
    <div>
       <h4>{title}</h4>
    </div>
  );
}

function MediaNav({title}){

  return(
    <div>
       <h4>{title}</h4>
    </div>
  );
}

function PageContentNav({title}){

  return(
    <div>
       <h4>{title}</h4>
    </div>
  )
}