import { GiFarmTractor, GiPig, GiMagnifyingGlass, GiGreenhouse, GiSprout, GiDatabase } from 'react-icons/gi';
import {GrBarChart} from 'react-icons/gr';

export const researchData = [
  {
    title: 'Agronomic Research',
    icon: <GiFarmTractor size={40} className='' />,
    description: 'Development of new farming techniques and technologies to improve crop production and profitability, including field trials, data collection, and analysis.'
  },
  {
    title: 'Livestock Research',
    icon: <GiPig size={40} className='' />,
    description: 'Studies to improve the health, nutrition, and productivity of livestock, including animal trials, data collection, and analysis.'
  },
  {
    title: 'Market Research',
    icon: <GiMagnifyingGlass size={40} className='' />,
    description: 'Analysis of agricultural markets, trends, and consumer preferences to inform product development, marketing strategies, and business planning.'
  },
  {
    title: 'Environmental Research',
    icon: <GiGreenhouse size={40} className='' />,
    description: 'Investigation of the environmental impact of agricultural practices and the development of sustainable farming systems, including soil and water quality analysis, carbon footprint assessments, and renewable energy solutions.'
  },
  {
    title: 'Soil and Environmental Research',
    icon: <GiSprout size={40} className='' />,
    description: 'Studies on soil health and the impact of agricultural practices on the environment, including soil nutrient analysis, soil conservation, and sustainable agriculture practices.'
  },
  {
    title: 'Technical Monitoring and Evaluation',
    icon: <GrBarChart size={40} className='' />,
    description: 'Monitors and evaluates the impact of agricultural projects, initiatives, and programs, using quantitative and qualitative methods, to ensure they are delivering the desired results.'
  },
  {
    title: 'Data Analysis and Interpretation',
    icon: <GiDatabase size={40} className='' />,
    description: 'Provides data analysis and interpretation services to support agricultural research and decision-making, including statistical analysis, spatial analysis, and modeling.'
  },
  {
    title: 'Knowledge Management and Dissemination',
    icon: <GiMagnifyingGlass size={40} className='' />,
    description: 'Develops and implements strategies for knowledge management and dissemination, to ensure that the insights and results of agricultural research are shared and used effectively.'
  },
  {
    title: 'Agricultural Technology Research',
    icon: <GiFarmTractor size={40} className='' />,
    description: 'Exploration of new technologies and innovations in agriculture, including precision agriculture, automation, and data analytics.'
  }
];
