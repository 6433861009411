import React from 'react';
import ProjectCard from '../UI/ProjectCard';

const projectsData = [
  {
    title: 'Smart farming System',
    description: 'This is an IOT system that is responsible for collecting,analyzing data on an indoor farm .It collects data such as temperature, humidity ,moisture ,light intensity and PH and nutrient content  and is able to use data collected to automate processes such as Irrigation ,temperature and many more on the farm .So far the project has been used by Torrid Mushrooms(Nkolokosa, Blantyre) in growing button mushroom'
  },
  {
    title: 'Groundnut Thresher',
    description: 'This is a machine that is responsible for shelling groundnuts. What makes it unique is that it does the shilling either mechanically or electronically depending on the setting and capacity of the farmer so much so that both small and larger holder farmers can afford .'
  },
  {
    title: 'Water Level monitoring system.',
    description: 'This is a system responsible for checking the level tank of  water on a farm and also triggering pumps automatically whenever the level of water is below the optimum standard .This was a system that we successfully designed and implemented in Baraka for Botha Investments.'
  },
  {
    title: 'Smart farming System',
    description: 'This is an IOT system that is responsible for collecting,analyzing data on an indoor farm .It collects data such as temperature, humidity ,moisture ,light intensity and PH and nutrient content  and is able to use data collected to automate processes such as Irrigation ,temperature and many more on the farm .So far the project has been used by Torrid Mushrooms(Nkolokosa, Blantyre) in growing button mushroom'
  },
]

const Portfolio = () => {
  return (
    <div className='pb-8' id='Portfolio'>
      <section class="ji gp uq">
        <div
          x-data="{ sectionTitle: `Latest Blogs & News`, sectionTitleText: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using.`}"
        >
          <div class="animate_top bb ze rj ki xn vq">
            <h2
              x-text="sectionTitle"
              class="fk vj pr kk wm on/5 gq/2 bb _b"
            >
              Our portifolio
            </h2>
            <p class="bb on/5 wo/5 hq" x-text="sectionTitleText"></p>
          </div>


        </div>
        <div className='mx-[10vw] flex flex-wrap justify-center gap-10 mt-20'>
          {
            projectsData.map(data => (
              <ProjectCard title = {data.title} description = {data.description}/>
            ))
          }
        </div>

      </section>
      <div className='flex flex-col items-center mt-10'>
        <h3 className='text-dominant text-xl font-bold sp'>Our Expertise</h3>
      </div>
      <div className='sm:container flex flex-col sm:flex-row gap-10 py-5 md:px-5 px-10 sm:px-0'>
        <div className='sm:w-[50%] flex flex-col gap-5'>
          <div>
            <p className='text-md font-roboto text-gray-500'>MONITORING SYSTEMS 90%</p>
            <div class="w-full bg-gray-200 h-1">
              <div class="bg-dominant h-1" style={{ width: "90%" }}></div>
            </div>
          </div>
          <div>
            <p className='text-md font-roboto text-gray-500'>WEBSITE DEVELOPMENT 85%</p>
            <div class="w-full bg-gray-200 h-1">
              <div class="bg-dominant h-1" style={{ width: "85%" }}></div>
            </div>
          </div>
          <div>
            <p className='text-md font-roboto text-gray-500'>FARM AUTOMATION 90%</p>
            <div class="w-full bg-gray-200 h-1">
              <div class="bg-dominant h-1" style={{ width: "95%" }}></div>
            </div>
          </div>
          <div>
            <p className='text-md font-roboto text-gray-500'>IRRIGATION SYSTEMS 80%</p>
            <div class="w-full bg-gray-200 h-1">
              <div class="bg-dominant h-1" style={{ width: "80%" }}></div>
            </div>
          </div>

        </div>
        <div className='sm:w-[50%] flex flex-col gap-5'>
          <div>
            <p className='text-md font-roboto text-gray-500'>INFRUSTRACTURE DESIGN 80%</p>
            <div class="w-full bg-gray-200 h-1">
              <div class="bg-dominant h-1" style={{ width: "80%" }}></div>
            </div>
          </div>
          <div>
            <p className='text-md font-roboto text-gray-500'>CONSULTATION 85%</p>
            <div class="w-full bg-gray-200 h-1">
              <div class="bg-dominant h-1" style={{ width: "85%" }}></div>
            </div>
          </div>
          <div>
            <p className='text-md font-roboto text-gray-500'>AGRICULTURAL TRAINING 90%</p>
            <div class="w-full bg-gray-200 h-1">
              <div class="bg-dominant h-1" style={{ width: "90%" }}></div>
            </div>
          </div>
          <div>
            <p className='text-md font-roboto text-gray-500'>SOIL TESTING 90%</p>
            <div class="w-full bg-gray-200 h-1">
              <div class="bg-dominant h-1" style={{ width: "90%" }}></div>
            </div>
          </div>

        </div>

      </div>
    </div>
  );
}

export default Portfolio;
