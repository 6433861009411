import React from 'react';
import { BiMenu } from 'react-icons/bi';
import {AiOutlineClose} from 'react-icons/ai';
import { Link } from 'react-scroll';

// import component 👇
import Drawer from 'react-modern-drawer';

//import styles 👇
import 'react-modern-drawer/dist/index.css'

const DrawerNav = () => {
    const [isOpen, setIsOpen] = React.useState(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

    return (
        <div className='drawerContainer'>
            <BiMenu size={30} className='text-green-800' onClick={toggleDrawer}/>
            <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction='top'
                style={{ minHeight: '50vh' }}
            >
                <div className='w-full'>
                    <div className='flex justify-end mt-10'>
                        <AiOutlineClose size={30} className='text-green-800' onClick={toggleDrawer}/>
                    </div>
                    <ul className='flex flex-col gap-5 items-center'>
                        <li 
                            className={`cursor-pointer text-green-800`}
                        >
                            <Link to='home' onClick={toggleDrawer} smooth={true} duration={1500} offset={-500}>Home</Link>
                        </li>
                        <li
                            className={`cursor-pointer text-green-800`}
                        >
                            <Link to='about' onClick={toggleDrawer}  smooth={true} duration={1500} offset={-70}>About</Link>
                        </li>
                        <li
                            className={`cursor-pointer text-green-800`} 
                        >
                            <Link to='services' onClick={toggleDrawer}  smooth={true} duration={1500} offset={-70}>Services</Link>
                        </li>
                        <li
                            className={`cursor-pointer text-green-800`}
                        >
                            <Link to='Portfolio' onClick={toggleDrawer}  smooth={true} duration={1500} offset={-70}>Portifolio</Link>
                        </li>
                        <li
                            className={`cursor-pointer text-green-800`}
                        >
                            <Link to='contact' onClick={toggleDrawer}  smooth={true} duration={1500} offset={-70}>Contact</Link>
                        </li>
                    </ul>
                </div>
            </Drawer>
        </div>
    )
}

export default DrawerNav