import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';

import './NavBar.css';

const NavBar = () => {
    const [currentPage, setCurrentPage] = useState('Home');
    const navigateToPage = (page) => {
        setCurrentPage(page);
    }
    const [sticky, setSticky] = useState(false);
    useEffect(()=>{
        const handleScroll = () => {
            setSticky(window.scrollY > 500);
        }
        window.addEventListener('scroll', handleScroll);
        return() => window.removeEventListener('scroll', handleScroll);
    });

    console.log(`${currentPage} ${sticky}`);
    
    return (
        <div className='hidden sm:block sm:sticky sm:top-0' style={{ zIndex: 2 }}>
            <div className='h-[4rem] w-full bg-green-700'>
                <div className='flex justify-center items-center h-full'>

                    <ul className='hidden sm:flex gap-10'>
                        <li 
                            className={`cursor-pointer text-white`}
                            onClick={() => navigateToPage('Home')}
                        >
                            <Link to='home' smooth={true} duration={1500} offset={-500}>Home</Link>
                        </li>
                        <li
                            className={`cursor-pointer text-white`}
                            onClick={() => navigateToPage('about')}
                        >
                            <Link to='about' smooth={true} duration={1500} offset={-70}>About</Link>
                        </li>
                        <li
                            className={`cursor-pointer text-white`}
                            onClick={() => navigateToPage('services')}  
                        >
                            <Link to='services' smooth={true} duration={1500} offset={-70}>Services</Link>
                        </li>
                        <li
                            className={`cursor-pointer text-white`}
                            onClick={() => navigateToPage('services')}  
                        >
                            <Link to='team' smooth={true} duration={1500} offset={-70}>Team</Link>
                        </li>
                        <li
                            className={`cursor-pointer text-white`} 
                            onClick={() => navigateToPage('portfolio')}
                        >
                            <Link to='Portfolio' smooth={true} duration={1500} offset={-70}>Portifolio</Link>
                        </li>
                        <li
                            className={`cursor-pointer text-white`}
                            onClick={() => navigateToPage('contact')}
                        >
                            <Link to='contact' smooth={true} duration={1500} offset={-70}>Contact</Link>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
        // <div className={`w-full h-[10vh] shadow-lg bg-white my_sticky`}>
        //     <div className='container h-full flex items-center justify-between'>
        //         <img src={logo} alt='logo' className='sm:w-[25%] w-[40%]'/>
        //         <ul className='hidden sm:flex gap-10'>
        //             <li 
        //                 className={`cursor-pointer ${currentPage === 'Home' ? 'text-green-600' : ''}`}
        //                 onClick={() => navigateToPage('Home')}
        //             >
        //                 <Link to='home' smooth={true} duration={1500} offset={-70}>Home</Link>
        //             </li>
        //             <li
        //               className={`cursor-pointer ${currentPage === 'services' ? 'text-green-600' : ''}`}
        //               onClick={() => navigateToPage('services')}  
        //             >
        //                 <Link to='services' smooth={true} duration={1500} offset={-70}>Services</Link>
        //             </li>
        //             <li
        //                 className={`cursor-pointer ${currentPage === 'about' ? 'text-green-600' : ''}`}
        //                 onClick={() => navigateToPage('about')}
        //             >
        //                 <Link to='about' smooth={true} duration={1500} offset={-70}>About</Link>
        //             </li>
        //             <li
        //                 className={`cursor-pointer ${currentPage === 'portfolio' ? 'text-green-600' : ''}`}
        //                 onClick={() => navigateToPage('portfolio')}
        //             >
        //                 <Link to='portfolio' smooth={true} duration={1500} offset={-70}>Portifolio</Link>
        //             </li>
        //             <li
        //                 className={`cursor-pointer ${currentPage === 'contact' ? 'text-green-600' : ''}`}
        //                 onClick={() => navigateToPage('contact')}
        //             >
        //                 <Link to='contact' smooth={true} duration={1500} offset={-70}>Contact</Link>
        //             </li>
        //         </ul>
        //     </div>
        // </div>
    );
}

export default NavBar;
