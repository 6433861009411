import { GiPlantSeed} from 'react-icons/gi';
import {FcElectricalSensor} from 'react-icons/fc';
import {MdOutlineSettingsSystemDaydream} from 'react-icons/md';
import {FaTools} from 'react-icons/fa';


export const businessData = [
    {
        title: 'Irrigation Equipment',
        icon: <FaTools size={40} className=''/>,
        description: 'We sell a range of irrigation equipment including drip irrigation systems, sprinkler systems, water pumps, and valves to help farmers efficiently water their crops and improve yields.'
    },
    {
        title: 'Sensors and Monitoring Systems',
        icon: <FcElectricalSensor size={40} className=''/>,
        description: 'We offer soil moisture sensors, weather sensors, and precision agriculture software for data analysis and decision-making. These tools help farmers optimize their use of resources and reduce waste.'
    },
    {
        title: 'Smart Farming Systems',
        icon: <MdOutlineSettingsSystemDaydream size={40} className=''/>,
        description: 'Our precision agriculture systems, automated weather stations, and remote control systems for water and nutrient management allow farmers to monitor and control their operations from anywhere. These systems improve efficiency and reduce labor costs.'
    },
    {
        title: 'Seeds',
        icon: <GiPlantSeed size={40} className=''/>,
        description: 'We sell high-yielding, disease-resistant, and climate-adapted seed varieties for various crops to help farmers increase yields and reduce crop loss due to disease or weather conditions.'
    },
]
