import React from 'react';

const ServiceCard = ({icon, title, description}) => {
    console.log(description);
    return (
        <div className='flex rounded-lg shadow-lg'>
            {/* <div className='w-[20%]'>
                <div className='w-14 h-14 flex justify-center items-center bg-gray-200 rounded-full'>
                   {icon} 
                </div>
                
            </div>
            <div className='ml-5'>
                <h1 className='text-xl text-center font-semibold text-gray-100'>{title}</h1>
                <p className='mt-2 text-gray-300'>{description}</p>
            </div>  */}
            <div class="p-6 bg-gray-100 rounded-lg">
                        
                <div class="mb-5">      
                {icon}       
                </div>
                        
                <h3 class="text-lg font-bold mb-2">
                    {title}
                </h3>
                        
                <p class="text-sm leading-6 text-gray-600">{description}</p>
                        
            </div>
        </div>
    );
}

export default ServiceCard;
