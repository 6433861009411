import { GiGrowth, GiTeacher, GiFarmer, GiBookshelf, GiNetworkBars } from 'react-icons/gi';


export const capacityBuildingData = [
    {
      title: 'Agricultural Consultancy Services',
      icon: <GiGrowth size={40} className='' />,
      description: 'We offer expert advice on crop selection, soil management, pest control, and other agriculture-related issues to help farmers optimize their operations and increase efficiency.'
    },
    {
      title: 'Agricultural Training Programs',
      icon: <GiTeacher size={40} className='' />,
      description: 'Customized training programs that provide farmers, ranchers, and agribusinesses with the skills and knowledge they need to improve their operations and achieve their goals. Some topics that we tackle include crop production, animal husbandry, agribusiness management, financial planning, and marketing.'
    },
    {
      title: 'Technical Assistance',
      icon: <GiFarmer size={40} className='' />,
      description: 'Hands-on support to help farmers, ranchers, and agribusinesses implement best practices, solve problems, and achieve their objectives. This may include on-site visits, remote consultations, and follow-up support.'
    },
    {
      title: 'Resource Development',
      icon: <GiBookshelf size={40} className='' />,
      description: 'Development of training materials, tools, and resources to support agricultural capacity building and provide farmers, ranchers, and agribusinesses with the information they need to succeed.'
    },
    {
      title: 'Network Building',
      icon: <GiNetworkBars size={40} className='' />,
      description: 'Creation of networks and communities of practice to connect farmers, ranchers, and agribusinesses with peers, experts, and resources, and foster learning and collaboration.'
    }
  ];
  