import walani from '../assets/walani.jpg';
import williams from '../assets/williams.jpg';
import sinkhani from '../assets/sinkhani.jpg';
import mwahara from '../assets/mwahara.png';
import ngwala from '../assets/ngwala.jpg';
import waliwali from '../assets/waliwali.jpg';
import mkagula from '../assets/mkagula.jpg';
import gondwe from '../assets/gondwe.jpg'

const teamData = [
    {
        image: walani,
        name: 'Chikumbutso Walani',
        position:'Chief Excecutive Officer',
        twLink: '',
        wapLink: '',
        fbLink: '',
        igLink: '',
        lnkLink: ''
    },
    {
        image: sinkhani,
        name: 'Ariheye Sinkhani',
        position:'Irrigation Engineer',
        twLink: '',
        wapLink: '',
        fbLink: '',
        igLink: '',
        lnkLink: ''
    },
    {
        image: gondwe,
        name: 'Mercy Gondwe',
        position:'Administration and Finance Manager',
        twLink: '',
        wapLink: '',
        fbLink: '',
        igLink: '',
        lnkLink: ''
    },
    {
        image: williams,
        name: 'Shadreck Willliam',
        position:'Agricultural specialist',
        twLink: '',
        wapLink: '',
        fbLink: '',
        igLink: '',
        lnkLink: ''
    },
    {
        image: mkagula,
        name: 'Faith Mkagula',
        position:'Agricultural Engineer',
        twLink: '',
        wapLink: '',
        fbLink: '',
        igLink: '',
        lnkLink: ''
    },
    {
        image: mwahara,
        name: 'Emmanuel Mwahara',
        position:'Software Engineer',
        twLink: '',
        wapLink: '',
        fbLink: '',
        igLink: '',
        lnkLink: ''
    },
    {
        image: waliwali,
        name: 'Annie WaliWali',
        position:'Programmer',
        twLink: '',
        wapLink: '',
        fbLink: '',
        igLink: '',
        lnkLink: ''
    },
    {
        image: ngwala,
        name: 'Emmanuel Ngwala',
        position:'Projects and Technical Engineer',
        twLink: '',
        wapLink: '',
        fbLink: '',
        igLink: '',
        lnkLink: ''
    },
]

export default teamData;