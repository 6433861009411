import React from 'react';

import hoodsmart from '../assets/hoodsmart.png';
import MENTOLOGO from '../assets/MENTOLOGO.png';
import Torrid_Mushroom from '../assets/Torrid_Mushroom.png';
import Bobby_Bricks_15 from '../assets/Bobby_Bricks_15.png';

const OurPartners = () => {
  return (
    <section class="pj vp mr">
      <div
        x-data="{ sectionTitle: `Trusted by Global Brands`, sectionTitleText: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using.`}"
      >
        <div class="animate_top bb ze rj ki xn vq">
          <h2
            x-text="sectionTitle"
            class="fk vj pr kk wm on/5 gq/2 bb _b"
          >
            Our partners
          </h2>
          <p class="bb on/5 wo/5 hq" x-text="sectionTitleText">Who are our trusted partners</p>
        </div>


      </div>
      <div className='mb-10'>
        <div className='w-full p-10 flex flex-col sm:flex-row gap-5 justify-center items-center sm:mt-5 sm:bg-slate-300'>
          <div className='sm:w-[20%] p-5 shadow bg-white'>
            <img className='sm:w-auto' src={hoodsmart} alt='partner' />
          </div>
          <div className='sm:w-[20%] p-5 shadow bg-white'>
            <img className='sm:w-auto' src={MENTOLOGO} alt='partner' />
          </div>
          <div className='sm:w-[20%] p-5 shadow bg-white'>
            <img className='sm:w-auto' src={Torrid_Mushroom} alt='partner' />
          </div>
          <div className='sm:w-[20%] p-5 shadow bg-white'>
            <img className='sm:w-auto' src={Bobby_Bricks_15} alt='partner' />
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurPartners;