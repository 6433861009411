import React from 'react';
import { BiBasketball, BiSearch } from 'react-icons/bi';
import { BsFileEarmarkTextFill } from 'react-icons/bs';

import AboutCard from '../UI/AboutCard';

const About = () => {

    const mission = 'To revolutionize agriculture through innovative technology,research  and expert consultation, empowering farmers to increase their yield and profitability while promoting sustainable agriculture practices';
    //const goal = 'To continuously research and develop new and innovative solutions that meet the changing needs of the agriculture industry\nTo provide expert consultation services that help farmers make informed decisions and achieve their agriculture goals.To provide high-quality training and education to farmers on the latest best practices in agriculture\nTo consistently deliver excellent customer service and establish long-lasting relationships with our clients.';
    const vision = 'Our vision is to become the leading provider of cutting-edge agriculture technology solutions, providing farmers with the knowledge and tools they need to succeed in an ever-evolving industry. We strive to be at the forefront of agricultural innovation, making a positive impact on the lives of farmers and the communities they serve';
    return (
        <div
            className='py-10'
            id='about'
        >
            <div className='mt-10 mx-5 sm:mx-[5vw]'>
                <div className='w-full flex flex-col sm:flex-row mb-10 sm:mb-0 sm:justify-between'>
                    <div className='flex flex-col items-center justify-center sm:w-[70%]'>
                        <div
                            className='w-full'
                        >
                            <div class="animate_top bb ze rj ki xn vq">
                                <h2
                                    x-text="sectionTitle"
                                    class="fk vj pr kk wm on/5 gq/2 bb _b"
                                >
                                    About us
                                </h2>
                                <p class="bb on/5 wo/5 hq" x-text="sectionTitleText"></p>
                            </div>


                        </div>
                        <p className='text-gray-400 text-md text-center mt-2'>Experience the Difference with Our Services</p>
                    </div>
                    <div className='sm:w-[50%] p-10 bg-gradient-to-br from-green-500 to-green-900 rounded-3xl shadow-xl'>
                        <h1 className='text-gray-200 text-2xl font-semibold mt-2'>SmartFarms</h1>
                        <p className='mt-3 text-gray-300'>
                            Smart Farms Ltd is a registered limited company in Malawi, incorporated on 25th January 2023 under the Companies Act No. 15 of 2013. With a focus on innovation and creativity, the company is dedicated to improving the agriculture sector through its offerings of agricultural engineering systems, consultation, and training. Established in 2019, the company has a strong track record of conducting research and development in the field of agriculture and is now taking its expertise to the next level as a fully incorporated business entity. With its unique combination of cutting-edge technology and expert agricultural knowledge, Smart Farms Ltd is poised to make a significant impact in the industry and help farmers and agribusinesses succeed.
                        </p>
                    </div>
                </div>
                <div className='flex flex-col sm:flex-row mt-20'>
                    <div className='pt-10 sm:pt-0 sm:w-[100%] sm:p-5'>
                        <h1 className='text-3xl text-green-800 text-center font-medium'>What Defines us</h1>
                        <p className='text-gray-400 text-md text-center mt-2'>Our mission, goals and vision</p>
                        <div className='flex flex-col flex-wrap md:flex-row lg:flex-nowrap gap-10 justify-center mt-[2rem]'>
                            <AboutCard message={mission} topic='Our mission' icon={<BiBasketball className='text-dominant' size={30} />} />
                            <AboutCard message={vision} topic='Our Vision' icon={<BsFileEarmarkTextFill className='text-dominant' size={30} />} />
                            <div className='flex flex-col items-center gap-3 p-5 bg-gray-100 md:w-[40%] sm:w-[30%]'>
                                <div className='w-14 h-14 rounded-full bg-gray-300 flex justify-center items-center'>
                                    <BiSearch className='text-dominant' size={30} />
                                </div>
                                <h2 className='text-xl text-gray-800 font-semibold'>Our Goals</h2>
                                <div className='flex justify-center'>
                                    <ul className='text-gray-600 font-libre list-disc px-6'>
                                        <li>To continuously research and develop new and innovative solutions that meet the changing needs of the agriculture industry.</li>
                                        <li>To provide expert consultation services that help farmers make informed decisions and achieve their agriculture goals</li>
                                        <li>To provide high-quality training and education to farmers on the latest best practices in agriculture.</li>
                                        <li>To consistently deliver excellent customer service and establish long-lasting relationships with our clients.</li>
                                        <li>To be a responsible and sustainable company, promoting environmentally friendly agriculture practices.</li>
                                        <li>To consistently deliver high-quality engineering designs that meet the needs and exceed the expectations of our clients.</li>
                                        <li>To become a recognized leader in the agriculture industry through our commitment to quality, sustainability, and customer satisfaction.</li>
                                        <li>To expand our reach and serve a larger customer base globally.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default About;
