import React from 'react';
import { Link } from 'react-scroll';
import {BsFacebook, BsLinkedin, BsTwitter, BsInstagram} from 'react-icons/bs';

const Footer = () => {
    return (
      <footer class="relative bg-blueGray-200 pt-8 pb-6"> 
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap text-left lg:text-left">
            <div class="w-full lg:w-6/12 px-4">
              <h4 class="text-3xl fonat-semibold text-blueGray-700">SmartFarms Ltd</h4>
              <h5 class="text-lg mt-0 mb-2 text-blueGray-600">
                Find us on any of these platforms, we respond 1-2 business days.
              </h5>
              <div class="mt-6 lg:mb-0 mb-6 flex gap-3">
                <button class="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 flex items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
                  <a href><BsTwitter size={20} className='text-green-800'/></a>
                  </button>
                <button class="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 flex items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
                  <a href='https://www.facebook.com/profile.php?id=100063644846692&mibextid=ZbWKwL' target='_blank' rel="noreferrer"><BsFacebook size={20} className='text-green-800'/></a>
                    </button>
                <button class="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 flex items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
                  <a href='https://www.linkedin.com/company/smartfrms/' target='_blank' rel="noreferrer"><BsLinkedin size={20} className='text-green-800'/></a>
                  </button>
                <button class="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 flex items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2" type="button">
                  <a href='https://www.instagram.com/smartfarms7/' target='_blank' rel="noreferrer"><BsInstagram size={20} className='text-green-800'/></a>
                  </button>
              </div>
            </div>
            <div class="w-full lg:w-6/12 px-4">
              <div class="flex flex-wrap items-top mb-6">
                <div class="w-full lg:w-4/12 px-4 ml-auto">
                  <span class="block uppercase text-blueGray-500 text-sm font-semibold mb-2">Useful Links</span>
                  <ul class="list-unstyled">
                    <li className='cursor-pointer'>
                      <Link class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" to='home' smooth={true} duration={1500} offset={-500}>Home</Link>
                    </li>
                    <li className='cursor-pointer'>
                      <Link class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" to='about' smooth={true} duration={1500} offset={-70}>About us</Link>
                    </li>
                    <li className='cursor-pointer'>
                      <Link class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" to='services' smooth={true} duration={1500} offset={-70}>Services</Link>
                    </li>
                    <li className='cursor-pointer'>
                      <Link class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" to='team' smooth={true} duration={1500} offset={-70}>our Team</Link>
                    </li>
                    <li className='cursor-pointer'>
                      <Link class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" to='Portfolio' smooth={true} duration={1500} offset={-70}>Portfolio</Link>
                    </li>
                    <li className='cursor-pointer'>
                      <Link class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" to='products' smooth={true} duration={1500} offset={-70}>Products</Link>
                    </li>
                    <li className='cursor-pointer'>
                      <Link class="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm" to='Portfolio' smooth={true} duration={1500} offset={-70}>Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr class="my-6 border-blueGray-300"/>
          <div class="flex flex-wrap items-center md:justify-between justify-center">
            <div class="w-full md:w-4/12 px-4 mx-auto text-center">
              <div class="text-sm text-blueGray-500 font-semibold py-1">
                Copyright © <span id="get-current-year">{new Date().getFullYear()}</span>
              </div>
            </div>
          </div>
        </div>
      </footer>

    );
}

export default Footer;
