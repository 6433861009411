import React, {useRef, useState} from 'react';
import {CiLocationOn} from 'react-icons/ci';
import {AiFillCloseCircle} from 'react-icons/ai';
import {BsFacebook, BsLinkedin, BsTwitter, BsInstagram, BsCheckCircleFill, BsEnvelope, BsPhone} from 'react-icons/bs';
import {GiPostOffice} from 'react-icons/gi';
import {IoIosSend} from 'react-icons/io';
import emailjs from '@emailjs/browser';

 import location from '../assets/loc.PNG';
 import { Modal, Spinner } from 'flowbite-react';

 


const Contact = () => {

  const form = useRef();
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const SubmitHandler = () => {
    setLoader(true);
    emailjs.sendForm('service_zc3jtm8', 'template_77ioe5q', form.current, 'user_dnyVcUQjIdwVWG7n8enPK')
      .then((result) => {
          setShowModal(true);
          setLoader(false);
          const table = form.current;
          const inputs = table.querySelectorAll('input, textarea');
          console.log(inputs);
          inputs.forEach(input => input.value = '');

      }, (error) => { 
          setErrorModal(true);
      });
  }
  console.log(process.env);
    return (
      
        <div 
          className='w-full bg-green-900' id='contact'
          style={{ 
            backgroundImage: `url(${location})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
           }}
        >
          <div className='p-2 sm:p-10' style={{ backgroundColor: 'rgba(0, 100, 20, .7)' }}>
            <div className='flex flex-col items-center'>
                <h3 className='text-gray-100 text-2xl font-bold sp'>Contact us</h3>
                <p className='text-gray-300'>Don't hesitate To reach out</p>
            </div>
            <div className='lg:mx-[15vw] flex flex-col-reverse sm:flex-row mt-10'>
                <div className='lg:w-[65%] flex justify-center bg-white'>
                    <form className='w-full flex flex-col gap-3 p-7' ref={form}>
                        <div className='flex justify-between items-center'>
                          <h3 className='text-green-900 font-semibold my-4'>Send us a message</h3>
                          <BsEnvelope size={30} className='text-green-900'/>
                        </div>
                        <input 
                          type='text' 
                          placeholder="your name" 
                          className='w-full p-2 border-2 border-gray-400 rounded-lg outline-none text-gray-600'
                          name = 'user_name'
                        />
                        <input 
                          type='text' 
                          placeholder='Your email' 
                          className='w-full p-2 border-2 border-gray-400 rounded-lg outline-none text-gray-600'
                          name="user_email"
                        />
                        <div className='flex gap-5'>
                          <textarea placeholder='message' name="message" rows={5} className='w-full p-2 border-2 border-gray-400 rounded-lg outline-none text-gray-600'></textarea>
                          <div className='h-full flex items-end'>
                              <div 
                              className='w-[80px] h-[80px] bg-green-900 rounded-full flex justify-center items-center text-gray-200 hover:bg-white hover:text-green-800 hover:border-[1px] hover:border-green-800 cursor-pointesur'
                              onClick={SubmitHandler}
                            >
                              {loader ? <Spinner aria-label="Default status example" /> : <IoIosSend size={50} className=''/>}
                            </div>
                            <Modal
                              show={showModal}
                              position="center"
                              onClose={() => setShowModal(false)}
                            >
                              <Modal.Body>
                                <div className="flex flex-col justify-center items-center">
                                  <div className='w-full flex justify-end' onClick={() => setShowModal(false)}>
                                    <AiFillCloseCircle size={30} className='text-red-600'/>
                                  </div>
                                  <BsCheckCircleFill size={50} className='text-dominant'/>
                                  <p>Email sent successfuly</p>
                                </div>
                              </Modal.Body>
                            </Modal>
                            <Modal
                              show={errorModal}
                              position="center"
                              onClose={() => setErrorModal(false)}
                            >
                              <Modal.Body>
                                <div className="flex flex-col justify-center items-center">
                                  <div className='w-full flex justify-end' onClick={() => setErrorModal(false)}>
                                    <AiFillCloseCircle size={30} className='text-red-600'/>
                                  </div>
                                  <AiFillCloseCircle size={50} className='text-red-600'/>
                                  <p>Failed to send email, Please try again later</p>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </div>
                          
                        </div>
                    </form>
                </div>
                <div className='sm:w-[35%] p-5' style={{ backgroundColor: 'rgba(0, 50, 0, .8)' }}>
                  <h3 className='text-2xl text-gray-200'>Contact Information</h3>
                  <ul className='mt-16 flex flex-col gap-5'>
                    <li className='text-gray-200 flex gap-3 items-center'>
                      <CiLocationOn size={30}/>
                      <p>We are located in Blantyre</p>
                    </li>
                    <li className='text-gray-200 flex gap-3 items-center'>
                    <BsEnvelope size={30}/>
                      <p>smartfarms7@gmail.com <br/>info@smartfarms.com</p>
                    </li>
                    <li className='text-gray-200 flex gap-3 items-center'>
                    <BsPhone size={30} />
                      <p>+265 888 998 100</p>
                    </li>
                    <li className='text-gray-200 flex gap-3 items-center'>
                    <GiPostOffice size={30} />
                      <p>P. O BOX 30197, Chichiri, Blantyre 3</p>
                    </li>
                  </ul>
                  <div className='flex items-center mt-10 gap-3'>
                    <BsTwitter size={20} className='text-gray-200'/>
                    <BsFacebook size={20} className='text-gray-200'/>
                    <BsLinkedin size={20} className='text-gray-200'/>
                    <BsInstagram size={20} className='text-gray-200'/>
                  </div>
                </div>
            </div>
          </div>
        </div>
     );
}

export default Contact;
