import React, { useState } from 'react'
import {HiChevronDown} from 'react-icons/hi';

export default function CmSideBar({page, setMainPage}) {
  const [pageContent, showPageContent] = useState(false);
  return (
    <div className="w-[15vw] min-h-screen bg-[#403f3e] text-white">
      <ul className='mt-8'>
        <div>
          <li className='pl-7 text-md font-semibold '>OVERVIEW</li>
          <ul className='w-full mt-2'>
            <li 
              className={`pl-16 p-3 cursor-pointer hover:bg-dominant ${ page === 'dashboard' ? 'bg-complimentary' : ''}`}
              onClick={() => {
                setMainPage('dashboard')
              }}
          >
              Dashboard
            </li>
          </ul>
        </div>
        <div className='mt-5'>
          <li className='pl-7 text-md font-semibold '>MANAGEMENT</li>
          <ul className='w-full mt-2'>
            <li 
              className={`pl-16 p-3 flex justify-between hover:bg-dominant ${ page === 'pageContent' ? 'bg-complimentary' : ''}`}
              onClick={() => {
                setMainPage('pageContent')
              }}
            >
              <p className='cursor-pointer'>Page Contents</p>
              <HiChevronDown className='cursor-pointer' size={20} onClick={() => showPageContent(!pageContent)}/>
            </li>
            <div>
              <ul className={`pl-[19px] w-full mt-2 transition-all ${pageContent ? 'block': 'hidden'}`}>
                <li className={`pl-16 p-3 cursor-pointer hover:bg-dominant`}>Dashboard</li>
              </ul>
            </div>
            <li 
              className={`pl-16 p-3 cursor-pointer hover:bg-dominant ${ page === 'articles' ? 'bg-complimentary' : ''}`}
              onClick={() => {
                setMainPage('articles')
              }}
            >
              Articles
            </li>
            <li 
              className={`pl-16 p-3 cursor-pointer hover:bg-dominant ${ page === 'news' ? 'bg-complimentary' : ''}`}
              onClick={() => {
                setMainPage('news')
              }}
            >
              News
            </li>
            <li 
              className={`pl-16 p-3 cursor-pointer hover:bg-dominant ${ page === 'team' ? 'bg-complimentary' : ''}`}
              onClick={() => {
                setMainPage('team')
              }}
            >
              Team
            </li>
            <li 
              className={`pl-16 p-3 cursor-pointer hover:bg-dominant ${ page === 'media' ? 'bg-complimentary' : ''}`}
              onClick={() => {
                setMainPage('media')
              }}
            >
              Media
            </li>
          </ul>
        </div>
      </ul>
    </div>
  )
}
