import React from 'react';

const AboutCard = ({message, topic, icon}) => {
    return (
        <div className='flex flex-col items-center gap-3 p-5 bg-gray-100 md:w-[40%] sm:w-[30%]'>
            <div className='w-14 h-14 rounded-full bg-gray-300 flex justify-center items-center'>
                {icon}
            </div>
            <h2 className='text-xl text-gray-800 font-semibold'>{topic}</h2>
            <p className='text-center text-gray-600 font-libre'>{message}</p>
        </div>
    );
}

export default AboutCard;
