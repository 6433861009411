import { createContext, useContext, useState } from "react";

const AuthContext = createContext({
    token: null,
    setToken: () => {}
});

export const AuthProvider = ({children}) => {
    const [token, _setToken] = useState(localStorage.getItem('ACCESS_TOKEN'));

    const setToken = (token) => {
        _setToken(token);
        if(token){
            localStorage.setItem('ACCESS_TOKEN', token)
        }else{
            localStorage.removeItem('ACCESS_TOKEN')
        }
    }

    return <AuthContext.Provider value={{
        token,
        setToken
    }}>
        {children}
    </AuthContext.Provider>
}

export const useAuthContext = () => useContext(AuthContext);

